<template>
  <v-dialog
    v-model="visibility"
    max-width="550"
    content-class="cross__dialog"
    :persistent="changed"
    :transition="$dialogTransition"
    @click:outside="showConfirmDialog"
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
          v-if="loading"
          absolute
          top
          indeterminate
          color="success"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loading">
          <v-btn fab small elevation="0" @click="showConfirmDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title>
        {{ getDialogTitle }}
      </v-card-title>
      <v-card-text class="pb-3">
        <div>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                color="success"
                v-model="inputAddress.name"
                hide-details="auto"
                :label="$t('table.name')"
                :maxLength="30"
                :error-messages="nameErrors"
                :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
                @keypress="validationForName($event)"
                @paste="validationForName($event)"
                @input="noEngText($event, false)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                color="success"
                v-model="inputAddress.surname"
                hide-details="auto"
                :label="$t('table.surname')"
                :error-messages="surnameErrors"
                :hint="onEngText ? '' : $t('form.hint.SurnameNoEngText')"
                :maxLength="30"
                @keypress="validationForSurname($event)"
                @paste="validationForSurname($event)"
                @input="noEngText($event, false)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                hide-details="auto"
                color="success"
                v-model="inputAddress.email"
                :label="$t('table.email')"
                :maxLength="50"
                :error-messages="emailErrors"
                @keypress="validationForEmail($event)"
                @paste="validationForEmail($event)"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                hide-details="auto"
                :error-messages="phoneErrors"
                color="success"
                v-model="inputAddress.phone"
                :label="$t('table.phone')"
                :maxLength="10"
                prefix="+38"
                @keypress="validationForNumbers($event)"
                @paste="validationForNumbers($event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                :items="activeCities"
                :loading="isLoadingCity"
                outlined
                dense
                :label="$t('form.city')"
                item-text="Description"
                item-value="Ref"
                hide-no-data
                :search-input.sync="searchCityQuery"
                :filter="filterCities"
                :error-messages="cityErrors"
                v-model="inputAddress.uuid_ref_city"
                color="success"
                item-color="success"
                @change="onChangeCity(inputAddress.uuid_ref_city)"
                @focus="onFocusCitiesHandler()"
                @keypress="validationForCities($event)"
                @paste="validationForCities($event)"
              >
                <template v-slot:item="{ item }">
                  <v-col class="col-12 pa-0 ma-0 mt-2">
                    <v-row no-gutters class="text-body-2">
                      {{ item.Description }}
                    </v-row>
                    <v-row no-gutters class="text-caption">
                      {{ item.RegionsDescription }}
                      <span v-if="item.RegionsDescription">&nbsp;-&nbsp;</span>
                      {{ item.AreaDescription }}
                    </v-row>
                  </v-col>
                </template>
                <template v-slot:append-item>
                  <div
                    v-if="!cityPagination.isLoadDisabled"
                    v-observer="{ nextPage: nextPageCities }"
                  />
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="activeWarehouse"
                :disabled="!inputAddress.uuid_ref_city"
                outlined
                dense
                :label="$t('form.department')"
                item-text="Description"
                item-value="Ref"
                return-object
                :no-data-text="$t('table.noData')"
                clearable
                :error-messages="warehouseErrors"
                v-model="inputAddress.uuid_ref_warehouse"
                color="success"
                item-color="success"
                @input="onSelectWarehouseHandler()"
                @keypress="validationForDepartment($event)"
                @paste="validationForDepartment($event)"
              >
                <template v-slot:append-item>
                  <div
                    v-if="!warehousePagination.isLoadDisabled"
                    v-observer="{ nextPage: nextPageWarehouse }"
                  ></div> </template
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters justify="space-between">
          <v-col cols="6" class="pr-1">
            <v-btn dark block class="br-10 text-transform-none" @click="showConfirmDialog">{{
              $t("btn.cancel")
            }}</v-btn>
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn
              block
              v-if="type === 'edit' && !permissions.manager"
              class="success-bg text-white text-transform-none br-10"
              :disabled="!changed"
              :loading="loading"
              @click="onUpdate"
              >{{ $t("btn.update") }}</v-btn
            >
            <v-btn
              block
              v-else-if="type === 'create'"
              class="success-bg text-white text-transform-none br-10"
              :disabled="!changed"
              :loading="loading"
              @click="onCreate"
              >{{ $t("btn.create") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onCancel"
    />
  </v-dialog>
</template>

<script>
import _ from "lodash";
import notifications from "@/mixins/notifications";
import user from "@/mixins/user";
import EventBus from "@/events/EventBus";
import novaPochta from "@/mixins/novaPochta";
import addressService from "@/services/request/address/addressService";
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import {
  validationForName,
  validationForSurname,
  validationForEmail,
  validationForDepartment,
  validationForCities,
  validationForNumbers,
  noEngText
} from "@/mixins/helpers";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import phoneMask from "@/validators/phoneMask";
import noEngTextValidator from "@/validators/noEngText";

export default {
  mixins: [notifications, user, novaPochta, validationMixin],
  components: { ConfirmLeftDialog },
  props: {
    visible: {
      require: true
    },
    address: {
      require: true,
      type: Object
    },
    type: {
      require: true
    }
  },
  validations: {
    inputAddress: {
      name: { required, noEngTextValidator },
      surname: { required, noEngTextValidator },
      email: { email },
      phone: { required, phoneMask },
      uuid_ref_city: { required },
      uuid_ref_warehouse: { required }
    }
  },
  data: () => ({
    loading: false,
    onEngText: true,
    changed: false,
    inputAddress: {},
    copyAddress: {},
    visibleConfirm: false,
    isLoadingCity: false,
    searchCityQuery: "",
  }),
  mounted() {
    this.onMounted();
  },
  methods: {
    validationForName,
    validationForSurname,
    validationForEmail,
    validationForDepartment,
    validationForCities,
    validationForNumbers,
    noEngText,
    onCancel() {
      this.$emit("close");
      this.$v.$reset();
    },
    showConfirmDialog() {
      this.changed ? (this.visibleConfirm = true) : this.onCancel();
    },
    async onMounted() {
      this.loading = true;
      
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.address) {
        if (this.address[key] === null) {
          this.address[key] = "";
        }
      }
      
      if (this.type === "edit") {
        if (this.address.phone) {
          this.address.phone = this.address.phone.replace("+38", "");
        }
        this.inputAddress = { ...this.address };
        this.copyAddress = { ...this.address };
      }
      await this.paginationInit();
      this.loading = false;
      
    },
    async paginationInit() {
      this.cityPagination.ref = this.inputAddress.uuid_ref_city;
      this.cityPagination.searchQuery = this.inputAddress.name_city;
      this.warehousePagination.cityRef = this.cityPagination.ref;
      this.warehousePagination.searchQuery = this.inputAddress.name_warehouse;
      await this.fetchNovaPochtaCities(this.cityPagination);
      await this.fetchWarehouses(this.warehousePagination);
      this.warehousePagination.searchQuery = "";
      [this.activeWarehouse, this.activeCities] = [this.warehouses, this.cities];
    },
    onFocusCitiesHandler() {
      if (!this.activeCities.length) {
        this.onFocusCities();
      }
    },
    onFocusCities: _.debounce(async function(nextPage = false) {
      this.isLoadingCity = true;
      try {
        await this.fetchNovaPochtaCities({ ...this.cityPagination });
        nextPage ? this.activeCities.push(...this.cities) : (this.activeCities = this.cities);
        this.cityPagination.isLoadDisabled = this.cities.length < this.cityPagination.perPage;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoadingCity = false;
      }
    }, 500),
    onChangeCity(city) {
      this.warehousePagination.searchQuery = "";
      this.warehousePagination.page = 1;
      if (this.type == "create") {
        this.onSelectCity(city, "createAdress");
      } else if (this.type == "edit") {
        this.onSelectCity(city, "editAdress");
      }
      this.inputAddress.uuid_ref_warehouse = null;
      this.searchAddressCityQuery = "";
    },
    onSelectWarehouseHandler() {
      if (this.type == "create") {
        this.onSelectWarehouse("createAdress");
      } else if (this.type == "edit") {
        this.onSelectWarehouse("editAdress");
      }
    },
    filterCities(queryText, itemText) {
      let searchArray = itemText.split("(");
      if (searchArray.length == 1) {
        searchArray = itemText.split(",");
      }
      return searchArray[0];
    },
    nextPageCities() {
      this.cityPagination.page++;
      this.onFocusCities(true);
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        console.log(this.inputAddress);
        this.loading = true;
        try {
          const formData = new FormData();
          formData.append("uuid", this.inputAddress.uuid);
          formData.append("name", this.inputAddress.name);
          formData.append("surname", this.inputAddress.surname);
          if (this.inputAddress.email) {
            formData.append("email", this.inputAddress.email);
          }
          formData.append("phone", `+38${this.inputAddress.phone}`);
          if (typeof this.inputAddress.uuid_ref_warehouse !== "object") {
            formData.append("uuid_ref_warehouse", this.inputAddress.uuid_ref_warehouse);
          } else {
            formData.append("uuid_ref_warehouse", this.inputAddress.uuid_ref_warehouse.Ref);
          }
          formData.append("category_of_warehouse", this.inputAddress.category_of_warehouse);
          formData.append("uuid_ref_city", this.inputAddress.uuid_ref_city);
          formData.append("name_city", this.inputAddress.name_city);
          formData.append("name_warehouse", this.inputAddress.name_warehouse);
          const result = await addressService.updateAddress(formData);
          EventBus.$emit("on-close-dialog");
          EventBus.$emit("on-modified", result.data?.object);
          this.$v.$reset();
          this.setSuccessNotification(this.$t("profile.successfully_updated"));
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      }
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const formData = new FormData();
          formData.append("name", this.inputAddress.name);
          formData.append("surname", this.inputAddress.surname);
          if (this.inputAddress.email) {
            formData.append("email", this.inputAddress.email);
          }
          formData.append("phone", `+38${this.inputAddress.phone}`);
          formData.append("uuid_ref_warehouse", this.inputAddress.uuid_ref_warehouse.Ref);
          formData.append(
            "category_of_warehouse",
            this.inputAddress.uuid_ref_warehouse.CategoryOfWarehouse
          );
          formData.append("uuid_ref_city", this.inputAddress.uuid_ref_city);
          formData.append("name_city", this.inputAddress.uuid_ref_warehouse.CityDescription);
          formData.append("name_warehouse", this.inputAddress.uuid_ref_warehouse.Description);
          const result = await addressService.createAddress(formData);
          EventBus.$emit("on-close-dialog");
          EventBus.$emit("on-init", result);
          this.$v.$reset();
          this.setSuccessNotification(this.$t("addresses.address_created"));
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      }
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.inputAddress.name.$dirty) {
        return errors;
      }
      if (!this.$v.inputAddress.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      !this.$v.inputAddress.name.required && errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.inputAddress.surname.$dirty) {
        return errors;
      }
      !this.$v.inputAddress.surname.required && errors.push(this.$t("form.errors.SurnameRequired"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.inputAddress.phone.$dirty) {
        return errors;
      }
      !this.$v.inputAddress.phone.required &&
        errors.push(this.$t("form.errors.PhoneNumberRequired"));
      !this.$v.inputAddress.phone.phoneMask &&
        errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.inputAddress.email.$dirty) {
        return errors;
      }
      !this.$v.inputAddress.email.email && errors.push(this.$t("form.errors.EmailMustBeValid"));
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.inputAddress.uuid_ref_city.$dirty) {
        return errors;
      }
      !this.$v.inputAddress.uuid_ref_city.required &&
        errors.push(this.$t("form.errors.CityRequired"));
      return errors;
    },
    warehouseErrors() {
      const errors = [];
      if (!this.$v.inputAddress.uuid_ref_warehouse.$dirty) {
        return errors;
      }
      !this.$v.inputAddress.uuid_ref_warehouse.required &&
        errors.push(this.$t("form.errors.DepartmentRequired"));
      return errors;
    },
    getDialogTitle() {
      switch (this.type) {
        case "create":
          return this.$t("addresses.createAdressBtn");
        case "edit":
          return this.$t("addresses.updateAddressesTitle");
        default:
          return "--";
      }
    }
  },
  watch: {
    inputAddress: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyAddress, this.inputAddress);
      }
    },
    searchCityQuery: {
      handler(e) {
        // if (e === null) {
        //   this.inputAddress.name_warehouse = "";
        //   this.inputAddress.name_city = "";
        //   this.copyAddress.name_city = "";
        //   this.copyAddress.name_warehouse = "";
        //   return;
        // }
        this.cityPagination.page = 1;
        this.warehousePagination.page = 1;
        this.cityPagination.searchQuery = e;
        this.onFocusCities();
      }
    }
  }
};
</script>
