<template>
  <div class="mb-2">
    <v-card class="my-2">
      <v-card-title class="py-0 pl-1">
        <v-row
          :class="[
            'align-center',
            'custom-v-row',
            $vuetify.breakpoint.smAndDown ? 'flex-column-reverse' : ''
          ]"
        >
          <v-col class="custom-v-col">
            <v-col cols="auto">
              {{ $t("addresses.adressesTitle") }}
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                depressed
                class="success-bg text-white text-transform-none br-10"
                dark
                @click="onClickCreate"
              >
                <v-icon :left="!$vuetify.breakpoint.xs">mdi-plus</v-icon>
                <div v-if="!$vuetify.breakpoint.xs">
                  {{ $t("addresses.createAdressBtn") }}
                </div>
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-slide-x-reverse-transition>
      <AddressesListTable />
    </v-slide-x-reverse-transition>
    <AddressesDialog
      :address="address"
      :type="dialogType"
      v-if="visibleDialog"
      :visible="visibleDialog"
      @close="visibleDialog = false"
    />
  </div>
</template>

<script>
import EventBus from "@/events/EventBus";
import { mapActions, mapGetters } from "vuex";
import * as actionTypes from "@/store/modules/pageLoadTime/types/actions";
import * as getterTypes from "@/store/modules/pageLoadTime/types/getters";
import AddressesListTable from "./AddressesListTable.vue";
import AddressesDialog from "./AddressesDialog.vue";

export default {
  components: {
    AddressesListTable,
    AddressesDialog
  },
  data: () => ({
    dialogType: "",
    visibleDialog: false,
    loadTime: 0
  }),
  async mounted() {
    EventBus.$on("openAddressEdit", async e => {
      this.openAddressEdit(e);
    });
    EventBus.$on("on-close-dialog", async e => {
      this.visibleDialog = false;
    });
    this.checkLoadTime();
  },
  methods: {
    ...mapActions("pageLoadTime", {
      setPageLoadTime: actionTypes.SET_PAGE_LOAD_TIME
    }),
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime);
    },
    openAddressEdit(e) {
      this.dialogType = "edit";
      this.visibleDialog = true;
      this.address = e;
    },
    onClickCreate() {
      this.dialogType = "create";
      this.visibleDialog = true;
      this.address = {};
    }
  },
  computed: {
    ...mapGetters("pageLoadTime", {
      startTime: getterTypes.GET_START_TIME
    })
  }
};
</script>
