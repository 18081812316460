<template>
  <v-row no-gutters align="center" justify="space-between">
    <v-col cols="12" :class="$vuetify.breakpoint.smAndDown ? 'mx-auto' : ''">
      <v-card class="mb-1 pt-2 pb-1">
        <ns-data-table
          :headers="addressesHeader"
          class="my-table supply"
          dense
          :request-service="getRequestService"
          :tableOffsetTop="165"
          :search-params="params"
        >
          <template v-slot:[`header.btn`]>
            <v-icon dense> mdi-cogs</v-icon>
          </template>
          <template v-slot:[`header.avatar`]>
            <v-icon dense> mdi-account-box</v-icon>
          </template>
          <template v-slot:[`header.name`]>
            <v-text-field
              solo
              dense
              hide-details
              :placeholder="$t('table.name')"
              clearable
              v-model="filter.name"
              @keypress="validationForName($event)"
              @paste="validationForName($event)"
              @input="onSearch"
            />
          </template>
          <template v-slot:[`header.surname`]>
            <v-text-field
              solo
              dense
              hide-details
              :placeholder="$t('table.surname')"
              clearable
              v-model="filter.surname"
              @keypress="validationForSurname($event)"
              @paste="validationForSurname($event)"
              @input="onSearch"
            />
          </template>
          <template v-slot:[`header.phone`]>
            <v-text-field
              solo
              dense
              hide-details
              :placeholder="$t('table.phone')"
              clearable
              v-model="filter.phone"
              @keypress="validationForPhoneNumber($event)"
              @paste="validationForPhoneNumber($event)"
              @input="onSearch"
            />
          </template>
          <template v-slot:[`header.email`]>
            <v-text-field
              solo
              dense
              hide-details
              :placeholder="$t('table.email')"
              clearable
              v-model="filter.email"
              @keypress="validationForEmail($event)"
              @paste="validationForEmail($event)"
              @input="onSearch"
            />
          </template>
          <template v-slot:[`header.name_city`]>
            <v-text-field
              solo
              dense
              hide-details
              :placeholder="$t('table.city')"
              clearable
              v-model="filter.name_city"
              @keypress="validationForCities($event)"
              @paste="validationForCities($event)"
              @input="onSearch"
            />
          </template>
          <template v-slot:[`header.name_warehouse`]>
            <v-text-field
              solo
              dense
              hide-details
              :placeholder="$t('table.department')"
              clearable
              v-model="filter.name_warehouse"
              @keypress="validationForDepartment($event)"
              @paste="validationForDepartment($event)"
              @input="onSearch"
            />
          </template>
          <template v-slot:[`item.btn`]="{ item }">
            <v-btn
              color="primary"
              small
              class="btn-edit"
              @click="openAddressEdit(item)"
              :class="{ sm: !isLarge }"
            >
              <v-icon dense> mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.avatar`]="{ item }">
            <v-avatar color="#05a6a6" size="23">
              <span class="text-white" style="font-size: 12px;">{{ getLetters(item) }}</span>
            </v-avatar>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            {{ item.phone | VMask("+38 (###) ##-##-###") }}
          </template>
          <template v-slot:[`item.email`]="{ item }">
            {{ item.email || "--" }}
          </template>
        </ns-data-table></v-card
      >
    </v-col>
  </v-row>
</template>

<script>
import _ from "lodash";
import SizeUi from "@/mixins/SizeUi";
import user from "@/mixins/user";
import EventBus from "@/events/EventBus";
import addressService from "@/services/request/address/addressService";
import {
  validationForPhoneNumber,
  validationForEmail,
  validationForName,
  validationForSurname,
  validationForCities,
  validationForDepartment
} from "@/mixins/helpers";

export default {
  mixins: [SizeUi, user],
  data: () => ({
    params: {},
    filter: {
      name: "",
      surname: "",
      phone: "",
      email: "",
      name_city: "",
      name_warehouse: ""
    }
  }),
  methods: {
    validationForPhoneNumber,
    validationForEmail,
    validationForName,
    validationForSurname,
    validationForCities,
    validationForDepartment,
    openAddressEdit(e) {
      EventBus.$emit("openAddressEdit", e);
    },
    getLetters(item) {
      let letters = "";
      if (item.name) {
        letters += item.name
          .trim()
          .charAt(0)
          .toUpperCase();
      }
      if (item.surname) {
        letters += item.surname
          .trim()
          .charAt(0)
          .toUpperCase();
      }
      return letters;
    },
    onSearch: _.debounce(function() {
      const searchItem = {};
      searchItem.uselike = true;
      Object.keys(this.filter).forEach(key => {
        if (this.filter[key] !== "") {
          searchItem[key] = this.filter[key];
        }
      });
      this.params = searchItem;
    }, 500)
  },
  computed: {
    getRequestService() {
      return addressService.getAddressesList;
    },
    addressesHeader() {
      const result = [
        { text: '#', value: 'order', sortable: false },
        {
          text: "",
          value: "btn",
          align: "center",
          sortable: false
        },
        {
          text: "",
          value: "avatar",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("table.name"),
          value: "name",
          align: "left",
          sortable: false
        },
        {
          text: this.$t("table.surname"),
          value: "surname",
          align: "left",
          sortable: false
        },
        {
          text: this.$t("table.phone"),
          value: "phone",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("table.email"),
          value: "email",
          align: "left",
          sortable: false
        },
        {
          text: this.$t("table.city"),
          value: "name_city",
          align: "left",
          sortable: false
        },
        {
          text: this.$t("table.department"),
          value: "name_warehouse",
          align: "left",
          sortable: false
        }
      ];
      return result;
    }
  }
};
</script>
